<template>
  <div>
    <v-row no-gutters>
      <v-col class="col-12 content-header text-left">
        <template v-if="showForm">
          <!-- <header class="auth-page-header align-left">
            <router-link :to="{ name: 'home' }">
              <img
                alt="Adhere.ly"
                class="logo"
                src="@/assets/logo.2.0.svg"
              >
            </router-link>
          </header> -->
          <h1 class="content-title">
            Set a new password
          </h1>

          <v-alert
            v-if="error"
            type="error"
            class="mt-5"
          >
            {{ error }}
          </v-alert>
        </template>

        <template v-if="success === true">
          <!-- <header class="auth-page-header align-left">
            <router-link :to="{ name: 'home' }">
              <img
                alt="Adhere.ly"
                class="logo"
                src="@/assets/logo.2.0.svg"
              >
            </router-link>
          </header> -->
          <h1 class="content-title">
            Password changed
          </h1>
          <div class="content-subtitle">
            You can now sign in with your new password
          </div>
          <v-btn
            link
            :to="{ name: 'signIn' }"
            x-large
            color="primary"
            class="mt-8"
          >
            Sign in
          </v-btn>
        </template>

        <template v-if="success === false">
          <!-- <header class="auth-page-header align-left">
            <router-link :to="{ name: 'home' }">
              <img
                alt="Adhere.ly"
                class="logo"
                src="@/assets/logo.2.0.svg"
              >
            </router-link>
          </header> -->
          <h1 class="content-title">
            Try resetting your password again
          </h1>
          <div class="content-subtitle">
            Your request to reset your password has expired or the link has
            already been used
          </div>
          <v-btn
            link
            :to="{ name: 'signIn' }"
            x-large
            color="primary"
            class="mt-8"
          >
            Home
          </v-btn>
        </template>
      </v-col>
    </v-row>
    <v-row class="content-body justify-center align-center">
      <v-col class="col-12 py-0">
        <auth-form-layout
          v-if="showForm"
          submit-button-text="Save and continue"
          @submit-action="savePassword()"
        >
          <v-col class="col-12 mb-4">
            <form-input
              v-model="password"
              :rules="rules.password"
              label="Set a password"
              type="password"
              autocomplete="new-password"
            />
            <form-input
              v-model="confirmPassword"
              :rules="rules.confirmPassword.concat(validateConfirmPassword)"
              label="Enter your password again"
              type="password"
              autocomplete="new-password"
            />
          </v-col>
        </auth-form-layout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from '@/firebase'
import AuthFormLayout from '@/components/auth/AuthFormLayout'
import FormInput from '@/components/form/FormInput'

export default {
  name: 'Action',
  components: { AuthFormLayout, FormInput },
  data() {
    return {
      success: null,
      email: null,
      password: '',
      confirmPassword: '',
      showForm: false,
      error: null,
      rules: {
        password: [
          v => !!v || 'Password is required',
          v => v.length >= 6 || 'Password should be at least 6 characters'
        ],
        confirmPassword: [
          v => !!v || 'Password is required'
        ]
      }
    }
  },
  mounted() {
    this.$store.dispatch('app/loading', true)

    firebase
      .auth()
      .verifyPasswordResetCode(this.$route.query.oobCode || '')
      .then(email => {
        this.email = email
        this.showForm = true
        this.$store.dispatch('app/loading', false)
      })
      .catch(() => {
        this.success = false
        this.$store.dispatch('app/loading', false)
      })
  },
  methods: {
    validateConfirmPassword(value) {
      return value == this.password || 'Passwords must match'
    },
    savePassword() {
      this.$store.dispatch('app/loading', true)

      firebase
        .auth()
        .confirmPasswordReset(this.$route.query.oobCode || '', this.password)
        .then(() => {
          this.success = true
          this.showForm = false
          this.$store.dispatch('app/loading', false)
        })
        .catch(e => {
          this.error = e.message
          this.$store.dispatch('app/loading', false)
        })
    }
  },
  head: {
    title: {
      inner: 'Reset password'
    }
  }
}
</script>

<style scoped lang="scss">
  .content-title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;
  }
</style>